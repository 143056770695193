<template>
  <div class='login'>
    <div class="blur"></div>
    <div class="form_body">
      <h3 class="title">校园云采 - 商超管理后台</h3>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="login_form" @keyup.enter.native="submitForm('ruleForm')">
        <el-form-item prop="account">
          <el-input v-model="ruleForm.account" placeholder="请输入账号">
            <img slot="prefix" :src="require('@/assets/img/login/user.png')">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" class="pwd">
          <el-input v-model="ruleForm.password" show-password placeholder="请输入密码">
            <img slot="prefix" :src="require('@/assets/img/login/pass.png')">
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="more">
            <div @click="goToUrl(1)">注册</div>
            <div @click="goToUrl(2)">忘记密码</div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="btnLoading" @click="submitForm('ruleForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <footers class="login_footers" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      btnLoading: false,
      ruleForm: {
        account: '',
        password: '',
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    this.$store.dispatch('logout')
  },
  mounted () {
    localStorage.clear()
  },
  methods: {
    goToUrl (value) {
      if (value == 1) {
        this.$router.push({ path: '/register' })
      } else {
        this.$router.push({ path: '/forgotPassword' })
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          this.$axios.post(this.$api.shopUserLogin, {
            account: this.ruleForm.account,
            password: this.ruleForm.password,
            menuList: '0', // 0：PC端菜单,1：移动端菜单
          }).then((res) => {
            const { result } = res.data
            this.$store.commit('setToken', result.token)
            this.$store.commit('setShopList', result.shopInfoVoList)
            if (result.sysMenuVoList.length > 0) {
              this.$router.replace('loginShop')
            }
            this.btnLoading = false
          }).catch(() => {
            this.btnLoading = false
          })
        }
      });
    },
  },
}
</script>

<style scoped lang='scss'>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;


  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../assets/img/login/login.png) no-repeat;
    background-size: cover;
    background-position: 100% 30%;
    filter: blur(4px);
  }

  .form_body {
    position: relative;
    padding: 22px 22px 0;
    border-radius: 6px;
    background-color: #fff;

    .title {
      color: #1a1a1a;
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 60px;
    }

    .login_form {
      width: 400px;

      /deep/.el-input__inner {
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding-left: 46px;
        border: 1px solid #dcdfe6;
        border-radius: 10px;
        background-color: #fff;

        &:hover {
          border-color: #c0c4cc;
        }

        &:focus {
          border-color: #069BBC;
        }
      }

      /deep/.el-input__prefix {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;

        img {
          width: 16px;
          height: 18px;
        }
      }

      .pwd {
        margin-bottom: 12px;
      }

      .more {
        display: flex;
        justify-content: space-between;
        color: #707070;

        div {
          cursor: pointer;
        }
      }

      .el-button {
        width: 100%;
        height: 50px;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
}
</style>
